import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import felisd2detail from "../../images/Felis-D2.webp"
import felisd2Doc from '../../docs/Felis-D2.pdf'

class Felisd2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Felis-D2 | Tumble Dryer Controller"
      description="Proalcs's Felis-D2 control panel for industrial drying machines designed for heavy industrial conditions" />
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-D2 </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div style={{ marginBottom: '20%' }} className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisd2detail} alt="felis-d2-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Felis-D2</h1>
            </div>
            <h2> Tumble Dryer Controller</h2>
            <p> It is a control panel for industrial drying machines designed for heavy industrial conditions. It is an easy-to-use, semi-professional serial controller with Custom Design LCD display and membrane keypad supported by icons. Resistant to vibration and dust. It has 16 user-defined programs. The design of the control panel is customized according to the needs and designed in accordance with the brand according to customer demands.</p>
          </Col>
        </Row>
        <Row>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
                <a   target="_blank" rel="noopener noreferrer"  href={felisd2Doc} >  <img className='product-detail-doc' src={dowDoc} alt="" />
                  <h1>Felis-D2</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Specifications</h1>
        </div>
        <Row className='lms-inside-content'>
          <Col>
            <li>  Fast commissioning via direct connection.<br></br></li>
            <li>  Flexible user program support, diagnostic control and a wide list of parameters.<br></br></li>
            <li>  Suitable for heating / steam / gas heating.<br></br></li>
            <li>  Tilting support support.<br></br></li>
            <li>  Humidity sensor and PT100 support. (Optional) <br></br></li>
            <li>  Suitable for coin operated<br></br></li>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 >Features</h1>
        </div>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs/Outputs</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>Onboard Level Sensor (Hydrostatic 0-100 cm) </p>
            <p> Temperature Sensor Input (-10~100 °C) </p>
            <p>Temperature Input PT100 (Optional)</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
            <p>1</p>
            <p>1</p>
          </Col>
        </Row>

        <Row className="product-table-row">
          <Col>
            <p>Analog Output</p>
          </Col>
          <Col>
            <p>AC Drive Control  0~10V DC  </p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Input </p>
          </Col>
          <Col>
            <p>
              5-24V DC/AC
        </p>
          </Col>
          <Col className="lastp">
            <p>16</p>
          </Col>
        </Row>

        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>9 User Programs</p>
          </Col>
          <Col className="lastp">
            <p></p>
          </Col>
        </Row>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>24 V AC </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0-60 °C </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%10 - 85 (Non-Condensing)</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Keyboard</p>
          </Col>
          <Col>
            <p>7 Keys </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Display </p>
          </Col>
          <Col>
            <p>80x50 mm Custom Designed LCD  </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Dimensions</p>
          </Col>
          <Col>
            <p>140x190x30 mm</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Display </p>
          </Col>
          <Col>
            <p>45x30 mm Custom Designed LCD</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col>
            <p>Micro Processor </p>
          </Col>
          <Col className="lastp" />
        </Row>

      </Container >
    </Layout >
  }
};

export default Felisd2
